// @ts-nocheck

import { Box, CircularProgress, Link, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { chat } from "../../../apis/assistant";

export const Prediction = (props: any) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [prediction, setPrediction] = useState<any>(null);

	useEffect(() => {
		setIsLoading(true);
		if (props?.storageKey) {
			const prediction = localStorage.getItem(`prediction-${props.storageKey}`);
			if (prediction) {
				setPrediction(JSON.parse(prediction));
				setIsLoading(false);
			}
		}
	}, []);

	useEffect(() => {
		if (!isLoading) {
			return;
		}
		if (prediction) {
			setIsLoading(false);
			return;
		}
		chat(props.sentence)
			.then((res: any) => {
				if (props.setBotMessageCallback) {
					props.setBotMessageCallback(res.data);
				}
				setPrediction(res.data);
				setIsLoading(false);
				if (props?.storageKey) {
					res.data["title"] = props.storageKey;
					localStorage.setItem(
						`prediction-${props.storageKey}`,
						JSON.stringify(res.data)
					);
				}
			})
			.catch((err: any) => {
				console.log(err);
				setIsLoading(false);
			});
	}, [isLoading]);

	return (
		<>
			<Box
				sx={{
					backgroundColor: "transparent",
					fontSize: "13.3333px",
					fontWeight: "400",
					px: "14px",
					py: "10px",
					borderRadius: "5px",
				}}
			>
				{isLoading && (
					<Box sx={{ display: "flex" }}>
						<CircularProgress sx={{ margin: "auto" }} color="inherit" />
					</Box>
				)}
				{prediction && (
					<>
						<Typography
							sx={{
								fontSize: "13.3333px",
								textAlign: "justify",
							}}
						>
							<Markdown
								rehypePlugins={[rehypeRaw]}
								components={{
									sup: ({ node, ...props }) => <sup {...props} />,
								}}
							>
								{prediction.answer}
							</Markdown>
						</Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								marginTop: "10px",
								width: "100%",
								color: "black !important",
							}}
						>
							<Typography
								variant="body2"
								color="textSecondary"
								component="p"
								sx={{
									fontSize: "13.3333px",
									fontStyle: "italic",
									fontWeight: "bold",
									margin: "auto",
								}}
							>
								Sources ({prediction.sources.length})
							</Typography>
							{prediction.sources.map((source: any, index: number) => {
								return (
									<Paper
										key={index}
										sx={{
											maxWidth: "100%",
											display: "flex",
											flexDirection: "row",
											p: "5px",
											m: "2px",
											alignContent: "center",
											justifyContent: "center",
											"&:hover": {
												backgroundColor: "#f0f0f0",
											},
											border: "1px solid #a5a5a5",
											borderRadius: "5px",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												width: "20px",
												height: "20px",
												borderRadius: "5px",
												backgroundColor: "#f0f0f0",
												fontSize: "10px",
												color: "black",
												fontWeight: "bold",
												border: "1px solid #a5a5a5",
												margin: "auto",
											}}
										>
											<Typography
												variant="body2"
												component="p"
												sx={{
													fontSize: "10px",
													margin: "auto",
												}}
											>
												{index + 1}
											</Typography>
										</Box>
										<Link
											href={source.url}
											target="_blank"
											variant="body2"
											sx={{
												fontSize: "13.3333px",
												marginLeft: "5px",
												wordBreak: "break-all",
												"@media (max-width: 600px)": {
													fontSize: "12px",
												},
												width: "100%",
											}}
										>
											{source.title}
										</Link>
									</Paper>
								);
							})}
						</Box>
					</>
				)}
			</Box>
		</>
	);
};
