import React from "react";
import { Navigate } from "react-router-dom";

import { Box, CssBaseline } from "@mui/material";
import { useAuth } from "../../components/login/loginContext";
import { DrawerHeader, LateralMenu } from "../menu/lateralMenu";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const { isLoggedIn } = useAuth();

	return (
		<>
			{isLoggedIn ? (
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<LateralMenu title={"PoC demo"} />
					<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
						<DrawerHeader />
						<React.Fragment>{children}</React.Fragment>
					</Box>
				</Box>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
};
