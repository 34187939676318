import {
	ChevronLeft,
	ChevronRight,
	DocumentScannerOutlined,
	ExitToApp,
	LocalPharmacy,
	Menu,
	SettingsVoice,
	Summarize,
} from "@mui/icons-material";
import {
	Box,
	CSSObject,
	Divider,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	AppBar as MuiAppBar,
	AppBarProps as MuiAppBarProps,
	Drawer as MuiDrawer,
	Theme,
	Toolbar,
	Typography,
	styled,
	useTheme,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../login/loginContext";

export const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		boxSizing: "border-box",
		...(open && {
			...openedMixin(theme),
			"& .MuiDrawer-paper": openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			"& .MuiDrawer-paper": closedMixin(theme),
		}),
	})
);

interface LateralMenuProps {
	title: string;
}

export const LateralMenu: React.FC<LateralMenuProps> = ({ title }) => {
	const { logout } = useAuth();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const path = window.location.pathname.split("/").slice(-1)[0];

	const handleDrawer = () => {
		setOpen(!open);
	};
	console.log("path", path);
	return (
		<>
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawer}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: "none" }),
						}}
					>
						<Menu />
					</IconButton>
					<Typography variant="h6" noWrap component="div">
						{title}
					</Typography>
					<Box sx={{ mr: 0, ml: "auto" }}>
						<img
							src="/assets/images/second_brain_logo__black_with_long_text_background_white.svg"
							alt="logo"
							style={{ height: "30px" }}
						/>{" "}
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer open={open} variant="permanent">
				<DrawerHeader>
					<IconButton onClick={handleDrawer}>
						{theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				{path !== "voices" && (
					<ListItem
						key="RFP summary"
						onClick={() => (window.location.href = "/")}
						disablePadding
						sx={{ display: "block" }}
					>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? "initial" : "center",
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								<Summarize />
							</ListItemIcon>

							<ListItemText primary="RFP summary" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					</ListItem>
				)}
				{path !== "voices" && (
					<ListItem
						key="Drug extraction"
						onClick={() => (window.location.href = "/extract/drug")}
						disablePadding
						sx={{ display: "block" }}
					>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? "initial" : "center",
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								<LocalPharmacy />
							</ListItemIcon>
							<ListItemText
								primary="Drug extraction"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					</ListItem>
				)}
				{path !== "voices" && (
					<ListItem
						key="RSE RFP answer"
						onClick={() => (window.location.href = "/rfps")}
						disablePadding
						sx={{ display: "block" }}
					>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? "initial" : "center",
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								<DocumentScannerOutlined />
							</ListItemIcon>
							<ListItemText primary="RSE RFP answer" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					</ListItem>
				)}
				<ListItem
					key="Voice recorder"
					onClick={() => (window.location.href = "/voices")}
					disablePadding
					sx={{ display: "block" }}
				>
					<ListItemButton
						sx={{
							minHeight: 48,
							justifyContent: open ? "initial" : "center",
							px: 2.5,
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : "auto",
								justifyContent: "center",
							}}
						>
							<SettingsVoice />
						</ListItemIcon>
						<ListItemText primary="Voice recorder" sx={{ opacity: open ? 1 : 0 }} />
					</ListItemButton>
				</ListItem>
				<Box
					sx={{
						display: "block",
						width: "100%",
						justifyContent: "center",
						position: "absolute",
						bottom: "30px",
						left: "50%",
						transform: "translate(-50%, 0)",
					}}
				>
					<ListItem
						key="Logout"
						onClick={logout}
						disablePadding
						sx={{ display: "block" }}
					>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? "initial" : "center",
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								<ExitToApp />
							</ListItemIcon>
							<ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					</ListItem>
					{open && (
						<>
							<Divider />
							<Box
								sx={{
									width: "100%",
									height: "10px",
									display: "block",
									py: 1,
									justifyContent: "center",
									fontSize: "10px",
									color: "#bababa",
								}}
							>
								<Typography sx={{ fontSize: "10px", textAlign: "center" }}>
									PoC platform
								</Typography>
								<Typography sx={{ fontSize: "10px", textAlign: "center" }}>
									Powered by SecondBrain © {new Date().getFullYear()}{" "}
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Drawer>
		</>
	);
};
