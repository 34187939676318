import { createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "../components/authentication/protected";
import { Error404 } from "../components/login/404";
import { DrugExtraction } from "../pages/drugExtractions";
import { SignInSide } from "../pages/login";
import { RFPExtract } from "../pages/rfpExtract";
import { RFPSummary } from "../pages/rfpSummary";
import { VoiceMatch } from "../pages/voiceMatch";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<RFPSummary />
			</ProtectedRoute>
		),
	},
	{
		path: "/voices",
		element: (
			<ProtectedRoute>
				<VoiceMatch />
			</ProtectedRoute>
		),
	},
	{
		path: "/extract/drug",
		element: (
			<ProtectedRoute>
				<DrugExtraction />
			</ProtectedRoute>
		),
	},
	{
		path: "/rfps",
		element: (
			<ProtectedRoute>
				<RFPExtract />
			</ProtectedRoute>
		),
	},
	{
		path: "/login",
		element: <SignInSide />,
	},
	{
		path: "*",
		element: <Error404 />,
	},
]);
export default router;
